import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { authConfig } from 'constants/config';
import Cookies from 'js-cookie';
import axios from 'axios';
import { logout, tokenReceived } from 'modal/authSlice';
import queryString from 'query-string';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = Cookies.get('accessToken');
    if (token) {
      headers.set('authorization', `Bearer ${token}`)
      headers.set('chuc-danh-id', getState()?.auth?.chucDanhChinhId)
    }
    return headers
  },
})

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions)
  if (result.error && result.error.status === 401) {
    const refresh_token = Cookies.get('refreshToken');
    const refreshResult = await axios({
      url: process.env.REACT_APP_API_URL + '/oauth2/token',
      method: 'POST',
      headers: {
        Authorization: `Basic ${window.btoa(`${authConfig.CLIENT_ID}:${authConfig.CLIENT_SECRET}`)}`,
      },
      data: queryString.stringify({
        'grant_type': 'refresh_token',
        'refresh_token': refresh_token
      })
    }).catch(error => {
      api.dispatch(logout())
    })
    if (refreshResult.data) {
      // store the new token
      api.dispatch(tokenReceived(refreshResult.data))
      // retry the initial query
      result = await baseQuery(args, api, extraOptions)
    } else {
      api.dispatch(logout())
    }
  }
  return result
}

export const api = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({})
});

export const { usePrefetch } = api