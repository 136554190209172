
import * as React from 'react'
import { useRoutes, useLocation, useParams } from 'react-router-dom'
import loadable from '@loadable/component'
import { routes } from './routes'
import { checkPermission, retry } from 'helpers'
import { withCookies } from 'react-cookie'
import { MasterLayout } from 'components/layouts'
import { useSelector } from 'react-redux'
import { selectUser } from 'modal/authSlice';
import { isEmpty } from 'lodash';
 
const AsyncComponent = ({
  componentName,
  updateContext,
  ...props
}) => {
  const userInfo = useSelector(selectUser);

  const location = useLocation()
  const params = useParams();
  if (props.status === 404) {
    window.location.replace('/404')
  }
  if (props.privateRouter && !props.accessToken) {
    window.location.replace('/signin')
  }
  if (props.componentName !== 'trangchu' && props.privateRouter && !isEmpty(props.permissions) && props.accessToken && !checkPermission(userInfo?.dsQuyen, props.permissions)) {
    window.location.replace('/403')
  }

  const Component = loadable(
    () => retry(() => import(`../components/pages/${componentName}`))
  )

  return (
    <Component
      {...props}
      match={{ params }}
      location={location}
    />
  )
}

const generateRoutes = (
  routes,
  props
) => {
  const { cookies } = props
  const accessToken = cookies.get('accessToken')
  return routes.map(({
    path,
    componentName,
    privateRouter = false,
    fetchData,
    status,
    permissions,
    type
  }) => ({
    element:
      privateRouter && accessToken ? (
        <MasterLayout>
          <AsyncComponent
            permissions={permissions}
            componentName={componentName}
            accessToken={accessToken}
            privateRouter={privateRouter}
            fetchData={fetchData}
            status={status}
            type={type}
          />
        </MasterLayout>
      ) : (
        <AsyncComponent
          componentName={componentName}
          accessToken={accessToken}
          privateRouter={privateRouter}
          fetchData={fetchData}
          status={status}
          type={type}
        />
      ),
      path
  }))
}

function RenderRoutes(props) {
  return useRoutes([
    ...generateRoutes(routes, props)
  ])
}

export default withCookies(RenderRoutes)
