import { api } from "services/api"

export const authApi = api.injectEndpoints({
  endpoints: builder => ({
    getMe: builder.query({
      query: (params) => ({
        url: '/api/me',
        method: 'GET',
        params
      }),
    }),
    getToken: builder.query({
      query: (params) => ({
        url: '/oauth2/token',
        method: 'GET',
        params
      }),
    }),
    logout: builder.mutation({
      query: () => ({
        url: '/logout',
        method: 'POST',
      }),
    })
  })
})

export const { useGetMeQuery, useLogoutMutation } = authApi;
