import React, { useState } from 'react';
import { Dropdown, Form, Layout, Menu, notification, Typography } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { isUrl } from 'helpers';
import PropTypes from 'prop-types';
import { logout, selectCurrentChucVuId, selectUser, setChucDanhId } from 'modal/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useLogoutMutation } from 'services/auth';
import clx from 'classnames';
import { useNavigate } from 'react-router';
import { Button, Input, ModalTraCuu } from 'components/common';
import messages from 'constants/messages';
import { usePostThayDoiMatKhauMutation, usePutSetPasswordMutation } from 'services/user';

const { Header } = Layout;
const { Title } = Typography;

const MasterHeader = (props) => {
  const { avatarUrl, collapsed, setCollapsed } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userInfo = useSelector(selectUser);
  const chucVuId = useSelector(selectCurrentChucVuId);
  const fullName = useSelector(state => state?.auth?.currentUser?.nguoiDung?.fullName);
  const maVaiTro = useSelector(state => state?.auth?.maVaiTro);

  const [open, setOpen] = useState(false)
  const [showPoupupSetPassword, setShowPoupupSetPassword] = useState(false)

  const [postLogout] = useLogoutMutation();
  const [postChangePassword, { isLoading }] = usePostThayDoiMatKhauMutation()
  const [putSetPassword, { isLoading: isLoadingSetPassword }] = usePutSetPasswordMutation()
  const [showMenuSetPassword, setShowMenuSetPassword] = useState(true);

  const handleLogout = async () => {
    postLogout()
    dispatch(logout())
  }

  const handleChangeChucDanh = (item) => {
    dispatch(setChucDanhId({
      chucDanhInfo: item
    }))
    navigate('/')
  }

  const openChangePassword = () => {
    setOpen(true)
  }

  const openSetPassword = () => {
    setShowPoupupSetPassword(true);
  }

  const onFinish = async (data) => {
    await postChangePassword({
      currentPassword: data?.currentPassword,
      newPassword: data?.newPassword
    }).unwrap().then(() => {
      notification.success({
        message: 'Thông báo',
        description: messages.changePasswordSuccess
      })
      setOpen(false)
    }).catch((err) => {
      notification.error({
        message: 'Thông báo',
        description: err?.status !== 500 ? err.data.title : messages.errorSystem
      })
    })
  }

  // Đặt mật khẩu cho app
  const onSetPassword = async (data) => {
    await putSetPassword({
      password: window.btoa(data?.password)
    }).unwrap().then(() => {
      notification.success({
        message: 'Thông báo',
        description: messages.setPasswordSuccess
      })
      setShowPoupupSetPassword(false)
      setShowMenuSetPassword(false)
    }).catch((err) => {
      notification.error({
        message: 'Thông báo',
        description: err?.status !== 500 ? err.data.title : messages.errorSystem
      })
    })
  }

  const authMenu = (
    <Menu
      style={{ maxWidth: 250 }}
      items={[
        ...userInfo?.dsChucDanh?.map?.(item => {
          if (item.chucVu) {
            return ({
              key: item.id,
              label: (
                <p onClick={() => handleChangeChucDanh(item)} className={clx("flex justify-between items-center", {
                  'text-[#228EF5]': item?.id === chucVuId
                })}>
                  {item?.chucVu?.ten || ''}
                  {
                    item?.id === chucVuId &&
                    <i className="itt-check" />
                  }
                </p>
              )
            })
          }
          return null
        }),
        {
          key: 'changePassword',
          label: (
            <>
              <p className="flex justify-start items-center" onClick={() => openChangePassword()}>
                <i className="itt-lock mr-2" />
                Đổi mật khẩu
              </p>
            </>
          ),
        },
        !userInfo?.nguoiDung?.changedPassword && showMenuSetPassword && {
          key: 'setPassword',
          label: (
            <>
              <p className="flex justify-start items-center" onClick={() => openSetPassword()}>
                <i className="itt-lock mr-2" />
                Đặt mật khẩu cho app
              </p>
            </>
          ),
        },
        {
          key: 'logout',
          label: (
            <>
              <p className="flex justify-start items-center" onClick={() => handleLogout()}>
                <i className="itt-poweroff mr-2" />
                Đăng xuất
              </p>
            </>
          ),
        },
      ]}
    />
  );

  return (
    <>
      <ModalTraCuu
        open={open}
        title="Đổi mật khẩu"
        footer={[
          <div className="text-right">
            <Button
              text="Đóng"
              className="button-close"
              type="default"
              icon={<i className="mr-2 itt-close text-sm opacity-40" />}
              onClick={() => {
                setOpen(false)
              }}
            />
            <Button
              text="Cập nhật"
              loading={isLoading}
              type="primary"
              htmlType="submit"
              form="themMoiNguoiDungDon"
              icon={<i className="mr-2 itt-check text-base" />}
            />
          </div>
        ]}
        destroyOnClose
        onCancel={() => setOpen(false)}
      >
        <div className="wrap-modal-content">
          <Form
            id="themMoiNguoiDungDon"
            layout="vertical"
            autoComplete="off"
            onFinish={onFinish}
          >
            <Form.Item
              label="Mật khẩu hiện tại"
              className="!mb-3"
              name="currentPassword"
              rules={[{ required: true, message: messages.messageRequired }]}
            >
              <Input type="password" placeholder="Mật khẩu hiện tại" />
            </Form.Item>
            <Form.Item
              label="Mật khẩu mới"
              className="!mb-3"
              name="newPassword"
              rules={[
                {
                  pattern: new RegExp("^(?=.*[a-z])(?=.*[0-9]).{8,}$"),
                  message: messages.validatePassword
                },
                { required: true, message: messages.messageRequired }
              ]}
            >
              <Input type="password" placeholder="Mật khẩu mới" />
            </Form.Item>
            <Form.Item
              label="Xác nhận mật khẩu mới"
              className="!mb-3"
              name="confirmPassword"
              rules={[
                { required: true, message: messages.messageRequired },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('newPassword') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(messages.confirmPasswordError));
                  },
                }),
              ]}
            >
              <Input type="password" placeholder="Xác nhận mật khẩu mới" />
            </Form.Item>
          </Form>
        </div>
      </ModalTraCuu>
      <ModalTraCuu
        open={showPoupupSetPassword}
        title="Đặt mật khẩu cho app"
        footer={[
          <div className="text-right">
            <Button
              text="Đóng"
              className="button-close"
              type="default"
              icon={<i className="mr-2 itt-close text-sm opacity-40" />}
              onClick={() => {
                setShowPoupupSetPassword(false)
              }}
            />
            <Button
              text="Cập nhật"
              loading={isLoadingSetPassword}
              type="primary"
              htmlType="submit"
              form="setPassword"
              icon={<i className="mr-2 itt-check text-base" />}
            />
          </div>
        ]}
        destroyOnClose
        onCancel={() => setShowPoupupSetPassword(false)}
      >
        <div className="wrap-modal-content">
          <Form
            id="setPassword"
            layout="vertical"
            autoComplete="off"
            onFinish={onSetPassword}
          >
            <Form.Item
              label="Mật khẩu"
              className="!mb-3"
              name="password"
              rules={[
                {
                  pattern: new RegExp("^(?=.*[a-z])(?=.*[0-9]).{8,}$"),
                  message: messages.validatePassword
                },
                { required: true, message: messages.messageRequired }
              ]}
            >
              <Input type="password" placeholder="Mật khẩu" />
            </Form.Item>
            <Form.Item
              label="Xác nhận mật khẩu"
              className="!mb-3"
              name="confirmPassword"
              rules={[
                { required: true, message: messages.messageRequired },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(messages.confirmPasswordError));
                  },
                }),
              ]}
            >
              <Input type="password" placeholder="Xác nhận mật khẩu" />
            </Form.Item>
          </Form>
        </div>
      </ModalTraCuu>
      <Header className="header">
        <div className="header-left">
          <div className="header-left__trigger" onClick={() => setCollapsed(!collapsed)}>
            <span className="itt-trigger" />
          </div>
          <Title level={2} className="header-left__title font-bold">Hệ thống csdl tiếp công dân và giải quyết đơn trên địa bàn thành phố đà nẵng</Title>
        </div>
        <Dropdown className="header-right" overlay={authMenu} overlayClassName="dropdown-content">
          <div className="header-auth">
            <img src={isUrl(avatarUrl) ? avatarUrl : `${process.env.PUBLIC_URL}/user.png`} className="header-auth__avatar" alt="avatar" />
            <span className="header-auth__fullname">{maVaiTro ? maVaiTro + '. ' : ''}{fullName || ''}</span>
            <DownOutlined className="header-auth__icon" />
          </div>
        </Dropdown>
      </Header>
    </>
  );
};

export default MasterHeader;

MasterHeader.propTypes = {
  avatarUrl: PropTypes.string,
  collapsed: PropTypes.bool,
  setCollapsed: PropTypes.func
}

MasterHeader.defaultProps = {
  avatarUrl: '',
  collapsed: false,
  setCollapsed: null
}