import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie'
import { authApi } from 'services/auth';

const initialState = {
  currentUser: null,
  token: '',
  refreshToken: '',
  chucDanhChinhId: null,
  maVaiTro: ''
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: () => {
      Cookies.remove('accessToken');
      Cookies.remove('refreshToken');
      setTimeout(() => {
        window.location.replace('/signin')
      }, 500)
    },
    tokenReceived: (state, action) => {
      if (action.payload.access_token && action.payload.refresh_token) {
        state.token = action.payload.access_token;
        state.refreshToken = action.payload.refresh_token;
        Cookies.set('accessToken', action.payload.access_token);
        Cookies.set('refreshToken', action.payload.refresh_token);
      }
    },
    setChucDanhId: (state, action) => {
      state.chucDanhChinhId = action.payload?.chucDanhInfo?.id;
      state.currentUser.dsQuyen = action.payload?.chucDanhInfo?.vaiTro?.dsQuyen;
      state.maVaiTro = action.payload?.chucDanhInfo?.chucVu?.ma;
    }
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(authApi.endpoints.getMe.matchFulfilled, (state, action) => {
        if (!state.currentUser) {
          state.currentUser = action?.payload
        }
        if (!state.maVaiTro) {
          state.maVaiTro = action?.payload?.dsChucDanh?.find?.(item => item.id === action?.payload?.chucDanhChinhId)?.chucVu?.ma
        }
        if (action?.payload?.chucDanhChinhId || action?.payload?.chucDanhChinhId === 0) {
          state.chucDanhChinhId = action?.payload?.chucDanhChinhId
        }
      })
  },
});

export const { logout, tokenReceived, setChucDanhId } = authSlice.actions;
export const selectUser = (state) => state.auth.currentUser;
export const selectCurrentChucVuId = (state) => state.auth.chucDanhChinhId

export default authSlice.reducer;
