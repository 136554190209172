const messages = {
  messageRequired: 'Không được bỏ trống trường này!',
  messageRequiredNguoiDungDon: 'Anh/Chị chưa chọn người đứng đơn',
  messageMaxLength: 'Không được nhập quá {value} kí tự',
  messageConfirm: 'Bạn có muốn xóa?',
  emptyText: 'Không có kết quả tìm kiếm',
  noData: 'Không có dữ liệu',
  cantDownFile: 'Không thể tải được tài liệu',
  xoaLuotTiepDan: 'Anh/Chị có đồng ý xóa thông tin về lượt tiếp công dân {value} không?',
  xoaLuotTiepDanThanhCong: 'Xóa lượt tiếp công dân thành công',
  luuHoSoThanhCong: 'Lưu hồ sơ thành công',
  luuThanhCong: 'Lưu thành công',
  messageThemMoiThanhCong: 'Thêm mới thành công',
  chuyenDonThanhCong: 'Chuyển đơn thành công',
  luuVaXuLyThanhCong: 'Lưu và xử lý thành công',
  luuVaGiaiQuyetThanhCong: 'Lưu và giải quyết thành công',
  luuLuotTiepThanhCong: 'Lưu lượt tiếp thành công',
  phanViecThanhCong: 'Phân việc thành công',
  thuHoiThanhCong: 'Thu hồi thành công',
  rutDonThanhCong: 'Rút đơn thành công',
  confirmChuyenDon: 'Anh/Chị có đồng ý chuyển đơn/vụ việc này không?',
  confirmThuLy: 'Anh/Chị muốn xác nhận lưu và giải quyết đơn này không?',
  confirmLuuHoSo: 'Anh/Chị muốn xác nhận lưu hồ sơ?',
  luuKetQuaTheoDoiThanhCong: 'Lưu kết quả theo dõi thành công',
  confirmChuyenTra: 'Sau khi xác nhận chuyển trả, đơn sẽ được trả về cho {value}?',
  confirmThuHoi: 'Sau khi xác nhận thu hồi, đơn sẽ được thu hồi tại {value}?',
  chuyenTraThanhCong: 'Chuyển trả thành công',
  emptyTraCuuDonText: 'Bạn chưa nhập dữ liệu tìm kiếm',
  confirmTrungDon: 'Anh/Chị cần xác nhận hồ sơ này có bị trùng không?',
  confirmXoaDon: 'Anh/Chị có đồng ý xóa thông tin đơn/vụ việc này không?',
  messageXoaDon: 'Xóa thông tin đơn/vụ việc thành công',
  confirmXoaNguoiDung: 'Anh/Chị có đồng ý xóa công chức này không?',
  messageXoaNguoiDung: 'Xóa công chức thành công',
  confirmXoaVaiTro: 'Anh/Chị có đồng ý xóa vai trò này không?',
  messageXoaVaiTro: 'Xóa vai trò thành công',
  confirmXoaChucVu: 'Anh/Chị có đồng ý xóa chức vụ này không?',
  messageXoaChucVu: 'Xóa chức vụ thành công',
  confirmXoaDanhMuc: 'Anh/Chị có đồng ý xóa danh mục này không?',
  messageXoaDanhMuc: 'Xóa danh mục thành công',
  messageRequiredTimkiemVanBan: 'Bạn chưa nhập dữ liệu tìm kiếm',
  confirmDownloadGiayBienNhan: 'Anh/Chị có đồng ý tải xuống Giấy biên nhận của đơn với Mã đơn {value} không?',
  messageCloseChanges: 'Đóng sẽ không lưu những thay đổi mà bạn đã thực hiện. Bạn có muốn đóng không ?',

  // Success message
  changePasswordSuccess: 'Đổi mật khẩu thành công',
  giaHanThanhCong: 'Gia hạn thành công',
  capNhatThanhCong: 'Cập nhật dữ liệu thành công',
  themMoiThanhCong: 'Thêm mới dữ liệu thành công',
  setPasswordSuccess: 'Đặt mật khẩu cho app thành công',

  // Validate message
  validateFileSize: 'Tệp đính kèm phải nhỏ hơn {value} MB',
  validateFile: 'Hệ thống chỉ hỗ trợ file có định dạng {accept} và kích cỡ file tối đa {value}MB. Vui lòng chọn file khác.',
  validatePassword: 'Mật khẩu phải có độ dài 8 kí tự, ít nhất một kí tự và một chữ số',

  // Error message
  errorSystem: 'Lỗi hệ thống',
  errorLayTaiLieu: 'Có lỗi xảy ra khi lấy tài liệu',
  confirmPasswordError: 'Mật khẩu không trùng khớp'
};

export default messages;