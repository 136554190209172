import React from 'react'
import RenderRouter from './routes/renderRoutes'
import { useGetMeQuery } from 'services/auth';
import { useLocation } from 'react-router';
import { Loading } from 'components/common';

function App() {
  const location = useLocation();
  const {
    isFetching,
    isLoading,
  } = useGetMeQuery(null, { skip: location.pathname === '/signin' || location.pathname === '/tra-cuu-cong-dan' });
  return (
    ((isFetching || isLoading)) ?
      (
        <Loading />
      ) : (
        <RenderRouter />
      )
  )
}

export default App
