export const LimitFileSize = 50;
export const maxFileTotal = 10;
export const maxPageSize = 9999;
export const maxDepthTree = 100;
export const maxLengthTextBox = 255;
export const maxLengthTextarea = 500;
export const maxLengthNumber = 20;
export const defaultPagination = {
  size: 10,
  page: 1,
};
export const placeholderSearch = 'Nhập tên người đứng đơn/địa chỉ/số điện thoại/CMND/CCCD';
export const placeholderDanhMuc = 'Nhập từ khóa';
export const placeholderTraCuu = 'Nhập tên/địa chỉ/số điện thoại/CMND/CCCD';
export const placeholderDateTime = 'dd/mm/yyyy hh:mm';
export const formatTime = {
  DateTime: 'DD/MM/YYYY',
  HoursMinutes: 'HH:mm',
  DayHours: 'DD/MM/YYYY HH:mm',
  DateTimeDash: 'DD/MM/YYYY - HH:mm',
};
export const monthOptions = [
  {
    name: 'Tháng 1',
    value: 'JANUARY',
  },
  {
    name: 'Tháng 2',
    value: 'FEBRUARY',
  },
  {
    name: 'Tháng 3',
    value: 'MARCH',
  },
  {
    name: 'Tháng 4',
    value: 'APRIL',
  },
  {
    name: 'Tháng 5',
    value: 'MAY',
  },
  {
    name: 'Tháng 6',
    value: 'JUNE',
  },
  {
    name: 'Tháng 7',
    value: 'JULY',
  },
  {
    name: 'Tháng 8',
    value: 'AUGUST',
  },
  {
    name: 'Tháng 9',
    value: 'SEPTEMBER',
  },
  {
    name: 'Tháng 10',
    value: 'OCTOBER',
  },
  {
    name: 'Tháng 11',
    value: 'NOVEMBER',
  },
  {
    name: 'Tháng 12',
    value: 'DECEMBER',
  },
];
export const gioiTinh = [
  { value: true, label: 'Nam' },
  { value: false, label: 'Nữ' },
];
