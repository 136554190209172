const permissions = {
  TIEPCONGDAN: ['TIEPCONGDAN'],
  // Thêm mới tiếp công dân
  TIEPCONGDAN_THEM_MOI: ['TIEPCONGDAN_THEM_MOI'],

  // Danh sách tiếp công dân
  TIEPCONGDAN_XEM_DANH_SACH: ['TIEPCONGDAN_XEM_DANH_SACH'],

  // Chi tiết tiếp công dân
  TIEPCONGDAN_XEM_CHI_TIET: ['TIEPCONGDAN_XEM_CHI_TIET'],

  // Chỉnh sửa tiếp công dân
  TIEPCONGDAN_CAP_NHAT: ['TIEPCONGDAN_CAP_NHAT', 'TIEPCONGDAN_XEM_CHI_TIET'],

  // Xóa tiếp công dân
  TIEPCONGDAN_XOA: ['TIEPCONGDAN_XOA'],

  // Xuất danh sách tiếp công dân
  TIEPCONGDAN_XUAT_DANH_SACH: ['TIEPCONGDAN_XUAT_DANH_SACH'],

  // Đăng ký găp lãnh đạo
  TIEPCONGDAN_DANG_KY_GAP_LD: ['TIEPCONGDAN_DANG_KY_GAP_LD'],

  // Xử lý giải quyết đơn
  XULYGIAIQUYET: ['XULYGIAIQUYET'],
  XULYGIAIQUYET_XEM_DANH_SACH: ['XULYGIAIQUYET_XEM_DANH_SACH'],
  XULYGIAIQUYET_XUAT_DANH_SACH: ['XULYGIAIQUYET_XUAT_DANH_SACH'],
  XULYGIAIQUYET_XEM_CHI_TIET: ['XULYGIAIQUYET_XEM_CHI_TIET'],
  XULYGIAIQUYET_THEM_MOI: ['XULYGIAIQUYET_THEM_MOI'],
  XULYGIAIQUYET_CAP_NHAT: ['XULYGIAIQUYET_CAP_NHAT'],
  XULYGIAIQUYET_RUT_DON: ['XULYGIAIQUYET_RUT_DON'],
  XULYGIAIQUYET_THU_HOI: ['XULYGIAIQUYET_THU_HOI'],
  XULYGIAIQUYET_GIAO_VIEC: ['XULYGIAIQUYET_GIAO_VIEC'],
  XULYGIAIQUYET_NHAN_VIEC: ['XULYGIAIQUYET_NHAN_VIEC'],
  XULYGIAIQUYET_XOA: ['XULYGIAIQUYET_XOA'],
  XULYGIAIQUYET_XEM_DANH_SACH_KET_QUA_DON_CHUYEN: ['XULYGIAIQUYET_XEM_DANH_SACH_KET_QUA_DON_CHUYEN'],

  // Theo dõi thực hiện giải quyết đơn
  THEODOITHUCHIEN: ['THEODOITHUCHIEN'],
  THEODOITHUCHIEN_XEM_DANH_SACH: ['THEODOITHUCHIEN_XEM_DANH_SACH'],
  THEODOITHUCHIEN_XUAT_DANH_SACH: ['THEODOITHUCHIEN_XUAT_DANH_SACH'],
  THEODOITHUCHIEN_XEM_CHI_TIET: ['THEODOITHUCHIEN_XEM_CHI_TIET'],
  THEODOITHUCHIEN_CAP_NHAT: ['THEODOITHUCHIEN_CAP_NHAT'],
  THEODOITHUCHIEN_NHAN_VIEC_THEO_DOI: ['THEODOITHUCHIEN_NHAN_VIEC_THEO_DOI'],
  THEODOITHUCHIEN_CHUYEN_THEO_DOI: ['THEODOITHUCHIEN_CHUYEN_THEO_DOI'],

  // Tra cứu đơn
  TRACUU: ['TRACUU'],
  TRACUU_XEM_DANH_SACH: ['TRACUU_XEM_DANH_SACH'],
  TRACUU_XEM_CHI_TIET: ['TRACUU_XEM_CHI_TIET'],
  TRACUU_DON_TO_CAO: ['TRACUU_DON_TO_CAO'],

  // Theo dõi giám sát
  THEODOIGIAMSAT: ['THEODOIGIAMSAT'],
  THEODOIGIAMSAT_XU_LY_GIAI_QUYET_DON: ['THEODOIGIAMSAT_XU_LY_GIAI_QUYET_DON'],
  THEODOIGIAMSAT_THEO_KY: ['THEODOIGIAMSAT_THEO_KY'],
  THEODOIGIAMSAT_DANH_SACH_HO_SO: ['THEODOIGIAMSAT_DANH_SACH_HO_SO'],
  THEODOIGIAMSAT_XEM_CHI_TIET: ['THEODOIGIAMSAT_XEM_CHI_TIET'],
  THEODOIGIAMSAT_XUAT_DANH_SACH: ['THEODOIGIAMSAT_XUAT_DANH_SACH'],
  THEODOIGIAMSAT_XU_LY_DON_THEO_NGUON: ['THEODOIGIAMSAT_XU_LY_DON_THEO_NGUON'],
  THEODOIGIAMSAT_THONG_KE_DON_CU: ['THEODOIGIAMSAT_THONG_KE_DON_CU'],

  // Thống kê báo cáo
  THONGKEBAOCAO: ['THONGKEBAOCAO'],
  THONGKEBAOCAO_TIEP_CONG_DAN: ['THONGKEBAOCAO_TIEP_CONG_DAN'],
  THONGKEBAOCAO_XU_LY_GIAI_QUYET_DON: ['THONGKEBAOCAO_XU_LY_GIAI_QUYET_DON'],
  THONGKEBAOCAO_THANH_TRA: ['THONGKEBAOCAO_THANH_TRA'],
  THONGKEBAOCAO_TIEP_CONG_DAN_VA_XU_LY_DON: ['THONGKEBAOCAO_TIEP_CONG_DAN_VA_XU_LY_DON'],
  THONGKEBAOCAO_XU_LY_DON_QUA_CAC_NAM: ['THONGKEBAOCAO_XU_LY_DON_QUA_CAC_NAM'],

  // QUẢN TRỊ HỆ THỐNG

  // Quản lý người dùng
  NGUOIDUNG: ['NGUOIDUNG'],
  NGUOIDUNG_XEM_DANH_SACH: ['NGUOIDUNG_XEM_DANH_SACH'],
  NGUOIDUNG_XEM_CHI_TIET: ['NGUOIDUNG_XEM_CHI_TIET'],
  NGUOIDUNG_THEM_MOI: ['NGUOIDUNG_THEM_MOI'],
  NGUOIDUNG_CAP_NHAT: ['NGUOIDUNG_CAP_NHAT'],
  NGUOIDUNG_XOA: ['NGUOIDUNG_XOA'],

  // Quản lý vai trò
  VAITRO: ['VAITRO'],
  VAITRO_XEM_DANH_SACH: ['VAITRO_XEM_DANH_SACH'],
  VAITRO_XEM_CHI_TIET: ['VAITRO_XEM_CHI_TIET'],
  VAITRO_THEM_MOI: ['VAITRO_THEM_MOI'],
  VAITRO_CAP_NHAT: ['VAITRO_CAP_NHAT'],
  VAITRO_XOA: ['VAITRO_XOA'],

  // Chức vụ
  CHUCDANH: ['CHUCDANH'],
  CHUCDANH_XEM_DANH_SACH: ['CHUCDANH_XEM_DANH_SACH'],
  CHUCDANH_XEM_CHI_TIET: ['CHUCDANH_XEM_CHI_TIET '],
  CHUCDANH_THEM_MOI: ['CHUCDANH_THEM_MOI'],
  CHUCDANH_CAP_NHAT: ['CHUCDANH_CAP_NHAT'],
  CHUCDANH_XOA: ['CHUCDANH_XOA'],

  // Phân quyền vai trò
  QUYEN: ['QUYEN'],
  QUYEN_XEM_DANH_SACH: ['QUYEN_XEM_DANH_SACH'],

  // QUẢN LÝ DANH MỤC
  // Danh mục cơ quan quản lý
  COQUANQUANLY: ['COQUANQUANLY'],
  COQUANQUANLY_XEM_DANH_SACH: ['COQUANQUANLY_XEM_DANH_SACH'],
  COQUANQUANLY_XEM_CHI_TIET: ['COQUANQUANLY_XEM_CHI_TIET'],
  COQUANQUANLY_THEM_MOI: ['COQUANQUANLY_THEM_MOI'],
  COQUANQUANLY_CAP_NHAT: ['COQUANQUANLY_CAP_NHAT', 'COQUANQUANLY_XEM_CHI_TIET'],
  COQUANQUANLY_XOA: ['COQUANQUANLY_XOA'],

  // Danh mục hành chính
  DONVIHANHCHINH: ['DONVIHANHCHINH'],
  DONVIHANHCHINH_XEM_DANH_SACH: ['DONVIHANHCHINH_XEM_DANH_SACH'],
  DONVIHANHCHINH_XEM_CHI_TIET: ['DONVIHANHCHINH_XEM_CHI_TIET'],
  DONVIHANHCHINH_THEM_MOI: ['DONVIHANHCHINH_THEM_MOI'],
  DONVIHANHCHINH_CAP_NHAT: ['DONVIHANHCHINH_CAP_NHAT'],
  DONVIHANHCHINH_XOA: ['DONVIHANHCHINH_XOA'],

  // Đơn vị ngoài hệ thống
  DONVINGOAIHETHONG: ['DONVINGOAIHETHONG'],
  DONVINGOAIHETHONG_XEM_DANH_SACH: ['DONVINGOAIHETHONG_XEM_DANH_SACH'],
  DONVINGOAIHETHONG_XEM_CHI_TIET: ['DONVINGOAIHETHONG_XEM_CHI_TIET'],
  DONVINGOAIHETHONG_THEM_MOI: ['DONVINGOAIHETHONG_THEM_MOI'],
  DONVINGOAIHETHONG_CAP_NHAT: ['DONVINGOAIHETHONG_CAP_NHAT'],
  DONVINGOAIHETHONG_XOA: ['DONVINGOAIHETHONG_XOA'],

  // Thẩm quyền giải quyết
  THAMQUYENGIAIQUYET: ['THAMQUYENGIAIQUYET'],
  THAMQUYENGIAIQUYET_XEM_DANH_SACH: ['THAMQUYENGIAIQUYET_XEM_DANH_SACH'],
  THAMQUYENGIAIQUYET_XEM_CHI_TIET: ['THAMQUYENGIAIQUYET_XEM_CHI_TIET'],
  THAMQUYENGIAIQUYET_THEM_MOI: ['THAMQUYENGIAIQUYET_THEM_MOI'],
  THAMQUYENGIAIQUYET_CAP_NHAT: ['THAMQUYENGIAIQUYET_CAP_NHAT'],
  THAMQUYENGIAIQUYET_XOA: ['THAMQUYENGIAIQUYET_XOA'],

  // Lĩnh vực đơn thư
  LINHVUCDONTHU: ['LINHVUCDONTHU'],
  LINHVUCDONTHU_XEM_DANH_SACH: ['LINHVUCDONTHU_XEM_DANH_SACH'],
  LINHVUCDONTHU_XEM_CHI_TIET: ['LINHVUCDONTHU_XEM_CHI_TIET'],
  LINHVUCDONTHU_THEM_MOI: ['LINHVUCDONTHU_THEM_MOI'],
  LINHVUCDONTHU_CAP_NHAT: ['LINHVUCDONTHU_CAP_NHAT'],
  LINHVUCDONTHU_XOA: ['LINHVUCDONTHU_XOA'],

  // Đối tượng thanh tra
  LINHVUCDOITUONGTHANHTRA: ['LINHVUCDOITUONGTHANHTRA'],
  LINHVUCDOITUONGTHANHTRA_XEM_DANH_SACH: ['LINHVUCDOITUONGTHANHTRA_XEM_DANH_SACH'],
  LINHVUCDOITUONGTHANHTRA_XEM_CHI_TIET: ['LINHVUCDOITUONGTHANHTRA_XEM_CHI_TIET'],
  LINHVUCDOITUONGTHANHTRA_THEM_MOI: ['LINHVUCDOITUONGTHANHTRA_THEM_MOI'],
  LINHVUCDOITUONGTHANHTRA_CAP_NHAT: ['LINHVUCDOITUONGTHANHTRA_CAP_NHAT'],
  LINHVUCDOITUONGTHANHTRA_XOA: ['LINHVUCDOITUONGTHANHTRA_XOA'],

  // Nguồn đơn
  NGUONDON: ['NGUONDON'],
  NGUONDON_XEM_DANH_SACH: ['NGUONDON_XEM_DANH_SACH'],
  NGUONDON_XEM_CHI_TIET: ['NGUONDON_XEM_CHI_TIET'],
  NGUONDON_THEM_MOI: ['NGUONDON_THEM_MOI'],
  NGUONDON_CAP_NHAT: ['NGUONDON_CAP_NHAT'],
  NGUONDON_XOA: ['NGUONDON_XOA'],

  // Quốc tịch
  QUOCTICH: ['QUOCTICH'],
  QUOCTICH_XEM_DANH_SACH: ['QUOCTICH_XEM_DANH_SACH'],
  QUOCTICH_XEM_CHI_TIET: ['QUOCTICH_XEM_CHI_TIET'],
  QUOCTICH_THEM_MOI: ['QUOCTICH_THEM_MOI'],
  QUOCTICH_CAP_NHAT: ['QUOCTICH_CAP_NHAT'],
  QUOCTICH_XOA: ['QUOCTICH_XOA'],

  // Dân tộc
  DANTOC: ['DANTOC'],
  DANTOC_XEM_DANH_SACH: ['DANTOC_XEM_DANH_SACH'],
  DANTOC_XEM_CHI_TIET: ['DANTOC_XEM_CHI_TIET'],
  DANTOC_THEM_MOI: ['DANTOC_THEM_MOI'],
  DANTOC_CAP_NHAT: ['DANTOC_CAP_NHAT', 'DANTOC_XEM_CHI_TIET'],
  DANTOC_XOA: ['DANTOC_XOA'],

  // Nơi cấp
  NOICAPGIAYTO: ['NOICAPGIAYTO'],
  NOICAPGIAYTO_XEM_DANH_SACH: ['NOICAPGIAYTO_XEM_DANH_SACH'],
  NOICAPGIAYTO_XEM_CHI_TIET: ['NOICAPGIAYTO_XEM_CHI_TIET'],
  NOICAPGIAYTO_THEM_MOI: ['NOICAPGIAYTO_THEM_MOI'],
  NOICAPGIAYTO_CAP_NHAT: ['NOICAPGIAYTO_CAP_NHAT'],
  NOICAPGIAYTO_XOA: ['NOICAPGIAYTO_XOA'],

  // Quản lý văn bản
  QUANLYVANBAN_TRA_CUU_PHONG_BAN_CA_NHAN: ['QUANLYVANBAN_TRA_CUU_PHONG_BAN_CA_NHAN']
}

export default permissions;